<template>
  <div>
    <div class="bg-white">
      <OtherLoading variant="warning" />
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
export default {
  components: {
    OtherLoading
  },
  beforeCreate: async function () {
    const queryString = decodeURIComponent(window.location.search);
    const params = new URLSearchParams(queryString);
    const searchParam = params.get("liff.state");
    const sessionId = decodeURIComponent(window.location.search).replace(
      "?sessionId=",
      ""
    );
    this.$liff
      .init({ liffId: this.$liff_Main_ID })
      .then(() => {
        if (searchParam) {
          this.$router.push(searchParam);
        } else if (sessionId) {
          this.$router.push(`/login?sessionId=${sessionId}`);
        } else {
          this.$router.push(`/login`);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }
};
</script>

<style lang="scss" scoped>
</style>